//Pantallas grandes
@media (min-width: 961px) {
  .contenedornav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: static;
    height: 5rem;
    align-items: center;
    font-size: 1.2rem;
    background: #f0f0f3;
    border-radius: 15px;
    box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
    padding: 1rem;
    width: 100%;

    .contenedornav--nav-menu {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      opacity: 1;
      transition: all 0.5s ease;
      list-style: none;
      right: 6vw;
      margin: 0;
      gap: 2rem;
    }

    .contenedornav-logo {
      display: flex;
      color: #2c3e50;
      justify-self: start;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .contenedornav-logo:hover {
      display: flex;
      justify-self: start;
      color: #2c3e50;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      align-items: center;
    }

    .contenedornav--logosiz {
      margin-left: 4vw;
      left: 4vw;
      top: 0.5rem;
    }

    .contenedornav-icon {
      margin-right: 0.5rem;
    }

    .nav-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      text-align: center;
      justify-content: flex-end;
      text-decoration: none;
    }

    .nav-menu .nav-item {
      height: 80px;
      border-bottom: 2px solid transparent;
      display: flex;
    }

    .nav-item:hover {
      border-bottom: 2px solid #2c3e50;

      h4 {
        text-decoration: none;
        color: #2c3e50;
      }
    }

    .nav-links {
      color: #2c3e50;
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 25%;

      a {
        text-decoration: none;
      }
    }

    .nav-links:hover {
      color: #2c3e50;
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 100%;

      a {
        text-decoration: none;
      }
    }

    .FaBars {
      color: #fff;
    }

    .contenedornav--iconomenu {
      display: none;
    }
  }
}

//Burger Menu
@media (max-width: 960px) {
  .contenedornav {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 0;
    z-index: 1;
    background: #f0f0f3;
    border-radius: 15px;
    box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
    padding: 1rem;
    height: 5rem;
  }

  .contenedornav--nav-menu {
    color: #2c3e50;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: fit-content;
    position: absolute;
    top: 5.5rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    padding: 2rem;
    gap: 1rem;
  }

  .contenedornav--logosiz {
    position: absolute;
    margin-left: 2.5vw;
    font-size: 2.2em;
    top: 0.5rem;

    .contenedornav-icon {
      color: #2c3e50;
      margin-right: 0.1em;
    }

    .contenedornav-logo {
      display: flex;
      gap: 1rem;
      color: #2c3e50;
      margin-right: 0.1em;
    }
  }

  .contenedornav--iconomenu {
    position: absolute;
    right: 6.5vw;
    margin: auto;
    font-size: 1.8rem;
    cursor: pointer;

    .fa-times {
      font-size: 2rem;
    }
  }

  .contenedornav--container {
    width: 90vw;
    margin: auto;
  }

  .contenedornav--nav-menu.active {
    list-style: none;
    top: 5.5rem;
    background: #f0f0f3;
    left: 10vw;
    opacity: 0.95;
    transition: all 0.6s ease;
    z-index: 1;
    border-radius: 15px;
    box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  }

  .nav-links {
    text-align: center;
    display: table;
    color: #2c3e50;
    text-decoration: none;
  }

  .nav-links:hover {
    color: #2c3e50;
    transform: scale(1);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}
