.cv {
  display: flex;
  width: auto;
  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2c3e50;
    padding: 0.5rem;
  }
}

.spanish {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;

  a p {
    text-align: center;
  }
}

.english {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  a p {
    text-align: center;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c3e50;
  padding-top: 0.5rem;
}
