.footer {
  // display: flex;
  // justify-content: center;
  // align-content: center;
  // width: 100%;
  // height: 9%;
  // background: #f0f0f3;
  // border-radius: 15px;
  // box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  // position: fixed;
  // bottom: 0;
  p {
    // margin: 0;
    // padding: 0;
    // width: auto;
  }
}

.hide {
  transform: translateY(100%);
  transition: transform 0.5s ease-out;
}
