:root {
  --colorTexto-color: #2c3e50;
}

.container {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important;
  background: #f0f0f3;
  border-radius: 15px;
  box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 768px;
}

.row {
  margin: 0.8em !important;
  justify-content: center;
  text-align: center;
  background-color: #cccccf;
  background: #f0f0f3;
  border-radius: 15px;
  box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  padding: 0 1rem;
  transition: transform 0.3s ease-in-out; /* Agregamos una transición suave para que el efecto se vea más natural */
}

.row:hover {
  transform: scale(1.01); /* Aumentamos el tamaño del elemento en un 5% */
}

.row p {
  text-align: justify;
  padding: 10px;
}

h2 {
  color: #2c3e50;
}

p {
  color: #2c3e50;
}

div.row {
  margin: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

.img-fluid {
  border-radius: 15px;
}

.arrow-frontr {
  font-size: 2em;
  margin: auto;
  svg {
    color: #2c3e50;
    vertical-align: super;
  }
}

.arrow-frontb {
  font-size: 2em;
  margin: auto;
  svg {
    color: #2c3e50;
    vertical-align: super;
  }
}

.iconoline {
  font-size: 2em;
  margin: auto;
  vertical-align: super;
}

.iconostart {
  font-size: 2em;
  margin: auto;
  vertical-align: super;
}

.iconos {
  margin: 0.3em;
  font-size: 4.7em;
  padding: 0.05em;
  border-radius: 25px;
  background: #f0f0f3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

a.linkedin {
  color: #2c3e50;
}

a.Gitg {
  color: #2c3e50;
}

a.insta {
  color: #2c3e50;
}

a.what {
  color: #2c3e50;
}

a.cvs {
  color: #2c3e50;
}

.li:hover {
  margin: 0.26em;
  font-size: 4.5em;
  padding: 0.1em;
  border-radius: 25px;
  color: #0077b5;
  background: #f0f0f3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}

.fac:hover {
  margin: 0.26em;
  font-size: 4.5em;
  padding: 0.1em;
  border-radius: 25px;

  color: #f0f0f3;
  background: #8a2387;
  background: -webkit-linear-gradient(to right, #f27121, #e94057, #8a2387);
  background: linear-gradient(to right, #f27121, #e94057, #8a2387);
}

.git:hover {
  margin: 0.26em;
  font-size: 4.5em;
  padding: 0.1em;
  border-radius: 25px;
  color: #24292e;
  background: #f0f0f3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}

.wat:hover {
  margin: 0.26em;
  font-size: 4.5em;
  padding: 0.1em;
  border-radius: 25px;
  color: #25d366;
  background: F0F0F3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}

.iconoscv {
  font-size: 5.2em;
  padding: 0.04em;
  border-radius: 10px;
  background: #f0f0f3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}

.iconoscv:hover {
  font-size: 5.25em;
  color: #ff2116;
  background: #f0f0f3;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}
.tipoFlyer {
  color: var(--colorTexto-color);
  padding: 1em;
}

.botones {
  padding: 0.5em;
  margin-top: 10em;
}

#root > div > div.container > div.row.botones > button {
  margin: 0.5em auto;
}

#root > div > div.container > div.row.input {
  padding: 0.5em;
}

#root
  > div
  > div.container
  > div:nth-child(4)
  > div.row
  > div:nth-child(1)
  > div
  > div
  > input[type="text"] {
  width: 100%;
  font-size: 1.2em;
  text-align: center;
  padding: 0;
  height: auto;
  border-radius: 15px;
}
.custom-select.oradores {
  border-radius: 15px;
}

.col-6 {
  position: relative;
}

.col-12 img {
  width: 100%;
}
#root > div > div.container > div.row.opciones {
  margin: 1em auto;
}

#root > div > div.container > div.row.opciones > div:nth-child(1) {
  display: flex;
  align-items: center;
}

#root > div > div.container > div.row.opciones > div > div:nth-child(1) {
  padding: 1px;
}
#root > div > div.container > div.row.opciones > div > div:nth-child(2) {
  padding: 1px;
}

.proyectos {
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  justify-content: center;
  margin: 0.5em auto;
  padding: 0.2em;
  background: #f0f0f3;
  border-radius: 15px;
  box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
}

.proyect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0.5em;
  max-width: 100%;
  min-width: 45%;
  height: 100vh;
  a {
    text-decoration: none;
    left: 0;
    margin-right: auto;
  }
  a:hover {
    text-decoration: none;
    color: #2c3e50;
    transform: scale(1.01);
  }
}

.proyectos a {
  padding: 0.2em;
  color: #2c3e50;
  text-decoration: none;
  font-size: 1.3em;
  border: transparent 0.5em;
  font-weight: 300;
  position: relative;
  z-index: 135;
}

.proyectos a:hover {
  color: #2c3e50;
  text-decoration: none;
  font-size: 1.4em;
}

.embed-responsive {
  padding: 0.5em;
  margin: 1em;
  border-radius: 15px;
}

.paginas {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  background: var(--fondo) !important;
  box-shadow: 10px 10px 30px #cccccf, -10px -10px 30px #ffffff;
}
