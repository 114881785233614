.scroll-custom {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden; /* oculta la barra de desplazamiento */
  overflow-y: hidden;
}

.addcontent {
  width: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
  position: relative;
  top: 0px;
  left: 0px;
}
