#root
  > div.nextui-c-fdHeMm.nextui-c-fdHeMm-dwxLNB-responsive-true.nextui-c-fdHeMm-ieymNKJ-css
  > div
  > div.nextui-c-kRHeuF.nextui-c-kRHeuF-igcdlpv-css.nextui-grid-item.nextui-grid-container
  > div
  > div
  > div.nextui-c-dfbgCO.nextui-c-dfbgCO-fGHEql-fluid-true.nextui-c-dfbgCO-idYxCHf-css
  > div {
  padding: 0.5rem;
}

#icon {
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

#icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}