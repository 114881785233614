:root {
  --primary: #f0f0f3;
}

.btn {
  padding: 8px 30px;
  border-radius: 55px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  text-decoration: none;
  a {
    text-decoration: none;
  }
}

.btn--primary {
  background-color: var(--primary);
  color: #2c3e50;
  border: 1px solid var(--primary);
  border-radius: 55px;
  box-shadow: 20px 20px 60px #cccccf, -20px -20px 60px #ffffff;
  text-decoration: none;
}

.btn--outline {
  background-color: transparent;
  color: #2c3e50;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 55px;
  width: 100%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: #f0f0f3;
  color: #2c3e50;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
  box-shadow: 20px 20px 60px #cccccf;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #f0f0f3;
  color: #242424;
  border-radius: 55px;
  border: 4px solid #b2b2b4;
  text-decoration: none;
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px #b2b2b4,
    inset -3px -3px 10px #ffffff, inset 3px 3px 10px #b2b2b4;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f0f0f3;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
}

a.btn-link:hover {
  text-decoration: none;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

.btn:focus {
  outline: none;
}

.green {
  background: #25ce4a;
  color: #fff;
  border: none;
}

.green:hover {
  background-color: #242424;
}
